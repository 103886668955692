// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad
.collectionWrap .lazyload-wrapper {
    height: 100%;
}
.none{
    display: none !important;
}
