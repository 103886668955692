// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad

.slick-arrow {

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    width: 31px !important;
    height: 31px !important;
    border-radius: 50%;
    font-size: 14px !important;
    padding: 7px;
    text-align: center;
    z-index: 99;
}

.slick-arrow:hover {
    color: #000;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.slick-arrow::before {
    color: #000;
}

.eventfindCarousel {
    .slick-next {
        right: -45px;

        @media (max-width: 991px) {
            right: -15px;
        }
    }

    .slick-prev {
        left: -45px;

        @media (max-width: 991px) {
            left: -15px;
        }
    }
}

.cardthemeSlider {
    padding-bottom: 57px;

    .slick-arrow {
        top: unset;
        bottom: 0;
        transform: none;
    }

    .slick-prev {
        left: calc(50% - 42px);
    }

    .slick-next {
        right: unset;
        left: calc(50% + 10px);
    }
}

.leftArrowsSlick {
    padding-bottom: 32px;

    .slick-arrow {
        top: unset;
        bottom: 0;
        transform: none;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: unset;
        left: 45px;
    }
}

.vericalSliderControl {
    .slick-arrow {
        background: transparent;
        box-shadow: none;
        border: 0;
    }

    .slick-prev {
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        padding: 0;
    }

    .slick-next {
        right: unset;
        left: 50%;
        bottom: -40px;
        top: unset;
        transform: translateX(-50%);
        padding: 0;
    }
}

.themeslickArrow {
    .slick-arrow {
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid #FFFFFF;
        fill: #fff;
    }
}

.moreCollection {
    .slick-next {
        right: -22px;

        @media (max-width: 767px) {
            right: -15px;
        }
    }

    .slick-prev {
        left: -22px;

        @media (max-width: 767px) {
            left: -15px;
        }
    }
}

.nomineeSlider {
    .slick-arrow {
        width: 24px;
        height: 24px;
        background: #b7b5ba;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 32px;
        color: #fff;
    }

    .slick-prev,
    .slick-next {
        top: unset;
        bottom: 4px;
    }

    .slick-prev {
        left: 8px;
    }

    .slick-next {
        right: 8px;
    }

    .slick-dots {
        bottom: 30px;

        li {
            width: 8px;
            height: 8px;
            button {
                background: #b7b5ba;
                opacity: 0.4;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border : 1px solid #fff;
                &::before {
                    display: none;
                }
            }
            &.slick-active{
                button{
                    opacity: 1;
                }
            }
        }
    }
}

.slick-next {
    @media (max-width: 767px) {
        right: -15px;
    }
}

.slick-prev {
    @media (max-width: 767px) {
        left: -15px;
    }
}

.slick-track {
    margin-left: 0;
}

.carousel-button-group {
    position: absolute;
}

.carousel-button-group .btn-next {
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    right: 0;
}

.slick-disabled {
    display: none !important;
}

.nomineeSlider {
    .slick-arrow {
        width: 24px;
        height: 24px;
        background: #b7b5ba;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 32px;
        color: #fff;
    }

    .slick-prev,
    .slick-next {
        top: unset;
        bottom: 4px;
    }

    .slick-prev {
        left: 8px;
    }

    .slick-next {
        right: 8px;
    }

    .slick-dots {
        bottom: 30px;

        li {
            width: 8px;
            height: 8px;
            button {
                background: #b7b5ba;
                opacity: 0.4;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border : 1px solid #fff;
                &::before {
                    display: none;
                }
            }
            &.slick-active{
                button{
                    opacity: 1;
                }
            }
        }
    }
}


@media (max-width: 576px) {
    .slick-disabled {
        display: inline-block !important;
    }

    .slick-arrow {
        // display: none !important;
    }

    .themeslickArrow {
        padding-bottom: 57px;

        .slick-arrow {
            top: unset;
            bottom: 0;
            transform: none;
        }

        .slick-prev {
            left: calc(50% - 42px);
        }

        .slick-next {
            right: unset;
            left: calc(50% + 10px);
        }
    }

    // .slick-prev,
    // .slick-next {
    //     top: unset;
    //     bottom: -75px;
    // }

    // .slick-prev,
    // .moreCollection .slick-prev {
    //     /* left: -15px; */
    //     left: calc(50% - 55px);
    // }

    // .slick-next,
    // .moreCollection .slick-next {
    //     right: calc(50% - 55px);
    // }

    .slick-track {
        transform: translate3d(0, 0, 0);
    }

    .slick-slide {
        text-align: center;
    }
}