// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar of the site/application.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad

.timer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
    .timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 12px;
    }
    .number{
        font-size: 22px;
        color: #F96634;
        font-weight: bold;
    }
}


