// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
/// @author Asim Hameed



.card {
    position: relative;
    -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    border-radius: 6px;
    height: 393px;
    overflow: hidden;
    width: 100%;
    // margin: 40px auto;
  
  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
  
    .media {
    height: 200px;
}

.p32 {
    padding: 32px;
}

.titleLine {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
}

.contentLine {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
}
  
  .end {
      width: 40%;
    }

}


.mT24 {
    margin-top: 24px;
}



.loadlines{
    .shine {
        background: #f6f7f8;
        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 800px 104px; 
        display: inline-block;
        position: relative; 
        
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards; 
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
        }
      
      box {
        height: 104px;
        width: 100px;
      }
      
      div {
        display: inline-flex;
        flex-direction: column; 
        margin-left: 25px;
        margin-top: 15px;
        vertical-align: top; 
      }
      
      lines {
        height: 15px;
        margin-top: 10px;
        width: 300px; 
      }
      
      photo {
        display: block!important;
        width: 325px; 
        height: 100px; 
        margin-top: 15px;
      }
      
  
      
      
}

@-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }