// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
/// @author Muneeba Amjad
// Bootom navigation for mobile devices of Cms
.hs-form{
    width: 100%;
    display: flex;
    position: relative;
    input[type="email"]{
        width: 100%;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    outline: none;
    padding: 14px 16px;
    font-size: 14px;
    transition: box-shadow 0.3s ease-out;
    border-radius: 40px;
    padding-right: 7.8125rem;
    background-color: rgba(255, 255, 255, 1);
    }
    input[type="submit"]{
      
    color: #fff;
    padding: 0.5rem 1rem;
    height: 100%;
    transition: transform 0.2s;
    border-radius: 0 40px 40px 0;
    background: transparent linear-gradient(180deg,#f96634 0%,#ef4678 100%) 0% 0% no-repeat padding-box;
    transform-origin: right;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 2px;
    }
    .hs_submit {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        .actions{
            height: 100%;
        }
    }
    .hs_email {
        flex: 1;
    }
    .hs-error-msgs{
        position: absolute;
        list-style: none;
        margin: 0;
        padding-left: 18px;
        li{
            label{
                color: red;
                font-size: 14px;
            }
        }
    }
    .hs_error_rollup{
       > .hs-error-msgs{
           display: none !important;
        }
    }
}